import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";

export const isAllowedAccess = (
  user: UserData,
  permissions: string[]
): boolean => {
  if (!user || user.permissions.length === 0) return false;
  if (permissions.length === 0) return false;

  const obj: Record<string, unknown> = {};

  for (let i = 0; i < user.permissions.length; i++) {
    if (!obj[user.permissions[i]]) {
      const element = user.permissions[i];
      obj[element] = true;
    }
  }

  for (let j = 0; j < permissions.length; j++) {
    if (obj[permissions[j]]) {
      return true;
    }
  }

  return false;
};
