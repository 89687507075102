import React from "react";
import { RefreshBanner } from "@quest-finance/quest-fe-shared/dist/auth";
import { NewVersionBanner } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { Link } from "react-router-dom";
import QuestCreditLogo from "../assets/images/quest-credit-logo.svg";
import UserNav from "./UserNav";
import "./Header.scss";

type TwoColHeaderProps = {
  hasNewVersion: boolean;
  isTokenExpiring: boolean;
  isAuthenticated: boolean;
};

const TwoColHeader: React.FunctionComponent<TwoColHeaderProps> = ({
  hasNewVersion,
  isTokenExpiring,
  isAuthenticated,
}: TwoColHeaderProps) => {
  return (
    <>
      <div className="banner-container">
        {hasNewVersion && <NewVersionBanner appName="QuestConnect" />}
        {isTokenExpiring && isAuthenticated && <RefreshBanner />}
      </div>
      <div className="layout-header">
        <Link className="logo" to="/">
          <img src={QuestCreditLogo} alt="quest-logo" />
        </Link>
        <UserNav />
      </div>
    </>
  );
};

export default TwoColHeader;
