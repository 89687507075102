import "./AssessmentStatus.scss";
import React, { useEffect, useMemo, useState } from "react";
import { SaveOutlined, EditOutlined } from "@ant-design/icons";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Button, Form, Select, Input, Popover } from "antd";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { REASON_TRIGGER_STATUSES } from "../../constants/statusGroup";
import AssessmentService from "../../services/AssessmentService";
import { AssessmentDetail } from "../../types/AssessmentDetail";
import ConfirmLmsModal from "../confirm-lms-modal/ConfirmLmsModal";
import StatusReasonModal from "../status-reason-modal/StatusReasonModal";

const { APPLICATION_STATUS_LABELS, APPLICATION_STATUSES } = applicationStatuses;

type AssessmentStatusProps = {
  assessment: AssessmentDetail;
  onSuccess: (assessment: Partial<AssessmentDetail>) => void;
};

const AssessmentStatus: React.FunctionComponent<AssessmentStatusProps> = ({
  assessment,
  onSuccess,
}: AssessmentStatusProps) => {
  const [status, setStatus] = useState(assessment.status);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isModalReasonVisible, setIsModalReasonVisible] = useState(false);
  const [isLMSModalVisible, setIsLMSModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const reason = useMemo(() => {
    if (assessment && assessment.reason) {
      const customReason = assessment.reason.allowCustomReason
        ? assessment.statusReason
        : "";
      if (assessment.reason.parent) {
        const parentReason = assessment.reason.parent.reason;
        if (assessment.reason.allowCustomReason) {
          return `${parentReason} - ${customReason}`;
        }

        return `${parentReason} - ${assessment.reason.reason}`;
      }

      return customReason ? customReason : assessment.reason.reason;
    }

    return "";
  }, [assessment]);
  const shouldDisplayReason = REASON_TRIGGER_STATUSES.includes(
    assessment.status
  );

  const updateAssessmentStatus = async (status: string) => {
    setSubmitLoading(true);
    try {
      const {
        data: { status: newStatus, statusReason, reason },
      } = await AssessmentService.updateAssessmentStatus(assessment.id, {
        status,
      });
      onSuccess({ status: newStatus, statusReason, reason });
      showSuccessPopUp("Status successfully saved");
      setSubmitLoading(false);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    setStatus(assessment.status);
  }, [assessment]);

  const handleStatusChange = (value: string) => {
    setStatus(value);
    if (REASON_TRIGGER_STATUSES.includes(value)) {
      setIsModalReasonVisible(true);
      setEditMode(false);
    }
    if (value === APPLICATION_STATUSES.IN_SETTLEMENT_WAITING_FOR_PAYMENT) {
      setIsLMSModalVisible(false);
    }
  };

  const handleStatusSubmit = () => {
    updateAssessmentStatus((status as unknown) as string);
  };

  const handleModalCancel = () => {
    setIsModalReasonVisible(false);
    setStatus(assessment.status);
  };

  const handleModalSuccess = ({
    status,
    statusReason,
    reason,
  }: AssessmentDetail) => {
    setIsModalReasonVisible(false);
    onSuccess({ status, statusReason, reason });
  };

  const onLmsCancel = () => {
    setIsLMSModalVisible(false);
    setStatus(assessment.status);
  };

  const onLmsSuccess = ({ status }: AssessmentDetail) => {
    setIsLMSModalVisible(false);
    onSuccess({ status });
  };

  const onLmsWarning = (status: string) => {
    setIsLMSModalVisible(false);
    onSuccess({ status });
  };

  const handleStatusEdit = () => {
    setIsModalReasonVisible(true);
    setEditMode(true);
  };

  return (
    <>
      <div className="assessment-status">
        <Form.Item label="Status" className="field">
          <Select
            disabled={submitLoading}
            value={status}
            onChange={(value) => handleStatusChange(value)}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            data-testid="status"
            virtual={false}
          >
            {Object.keys(APPLICATION_STATUS_LABELS).map((status) => (
              <Select.Option key={status} value={status}>
                {APPLICATION_STATUS_LABELS[status]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {shouldDisplayReason && (
          <Form.Item label="Reason" className="field">
            <Input.Group compact>
              <Popover
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                content={<div className="status-reason">{reason}</div>}
                placement="bottom"
              >
                <Input
                  style={{
                    width: "calc(100% - 45px)",
                  }}
                  className="reason"
                  value={reason}
                  readOnly
                  data-testid="status-reason"
                />
              </Popover>
              <Button
                onClick={handleStatusEdit}
                data-testid="status-edit-button"
              >
                <EditOutlined />
              </Button>
            </Input.Group>
          </Form.Item>
        )}

        <Form.Item label="">
          <Button
            type="default"
            onClick={handleStatusSubmit}
            disabled={submitLoading}
            data-testid="status-save-button"
          >
            <SaveOutlined />
          </Button>
        </Form.Item>
      </div>
      <StatusReasonModal
        editMode={editMode}
        assessment={assessment}
        status={status}
        visible={isModalReasonVisible}
        onCancel={handleModalCancel}
        onSuccess={handleModalSuccess}
      />
      <ConfirmLmsModal
        assessment={assessment}
        status={status}
        visible={isLMSModalVisible}
        onCancel={onLmsCancel}
        onSuccess={onLmsSuccess}
        onWarning={onLmsWarning}
      />
    </>
  );
};

export default AssessmentStatus;
