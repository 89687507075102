import { ClientResponse } from "./ClientResponse";
import { RoleResponse } from "./RoleResponse";
import { USER_STATUS } from "./User";

export interface UserResponse {
  id: number;
  clientId: number;
  parentId: number | null;
  accreditationId: number;
  entityId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  client: Pick<ClientResponse, "name">;
  lastLoginAt: Date | null;
  status: USER_STATUS;
  roles: RoleResponse[];
}

export const userResponseDefaultValue: UserResponse = {
  id: 0,
  clientId: 0,
  parentId: null,
  accreditationId: 0,
  entityId: 0,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
  client: {
    name: "",
  },
  status: USER_STATUS.ACTIVE,
  lastLoginAt: null,
  roles: [],
};
