import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { ClientForm } from "../../types/ClientForm";
import { ClientResponse } from "../../types/ClientResponse";
import * as types from "../types/clientAdmin";

export const getClientDetails = (
  clientId?: number
): types.GetClientDetails => ({
  type: types.GET_CLIENT_DETAILS,
  clientId,
});

export const getClientDetailsSuccess = (
  client: ClientResponse
): types.GetClientDetailsSuccess => ({
  type: types.GET_CLIENT_DETAILS_SUCCESS,
  client,
});

export const getClientDetailsFailed = (): types.GetClientDetailsFailed => ({
  type: types.GET_CLIENT_DETAILS_FAILED,
});

export const saveClient = (
  clientForm: ClientForm,
  clientId?: number
): types.SaveClient => ({
  type: types.SAVE_CLIENT,
  clientForm,
  clientId,
});

export const saveClientSuccess = (): types.SaveClientSuccess => ({
  type: types.SAVE_CLIENT_SUCCESS,
});

export const saveClientFailed = (): types.SaveClientFailed => ({
  type: types.SAVE_CLIENT_FAILED,
});

export const resetFormState = (): types.ResetFormState => ({
  type: types.RESET_FORM_STATE,
});

export const updateClientForm = (
  clientForm: Partial<ClientForm>
): types.UpdateClientForm => ({
  type: types.UPDATE_CLIENT_FORM,
  clientForm,
});

export const getPricing = (): types.GetPricing => ({
  type: types.GET_PRICING,
});

export const getPricingSuccess = (
  pricing: Record<string, string>
): types.GetPricingSuccess => ({
  type: types.GET_PRICING_SUCCESS,
  pricing,
});

export const getPricingFailed = (): types.GetPricingFailed => ({
  type: types.GET_PRICING_FAILED,
});

export const getClientAuditLogs = (
  payload: AuditLogListRequest
): types.GetClientAuditLogs => ({
  type: types.GET_CLIENT_AUDIT_LOGS,
  payload,
});

export const getClientAuditLogsSuccess = (
  auditLogs: ListResponse<AuditLogResponse>
): types.GetClientAuditLogsSuccess => ({
  type: types.GET_CLIENT_AUDIT_LOGS_SUCCESS,
  auditLogs,
});

export const getClientAuditLogsFailed = (): types.GetClientAuditLogsFailed => ({
  type: types.GET_CLIENT_AUDIT_LOGS_FAILED,
});
