import React, { useCallback, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./LinkedAccounts.scss";
import {
  ApplicationResponse,
  applicationStatuses,
} from "@quest-finance/quest-fe-shared/dist/application/";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { replaceAll } from "@quest-finance/quest-fe-shared/dist/common/utils/string";
import { Spin } from "antd";
import cs from "classnames";
import lodash from "lodash";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showErrorPopUp } from "../../../../common/components/ShowNotification/showNotification";
import { LONG_DATE_FORMAT } from "../../../../common/contstants/app";
import { processError } from "../../../../common/utils/error";
import { assessmentResponseSelector } from "../../../selectors/assessmentDetail";
import AssessmentService from "../../../services/AssessmentService";
import { AssessmentDetail } from "../../../types/AssessmentDetail";
import { AssessmentTypes } from "../../../types/AssessmentType";

const LinkedAccounts: React.FC = () => {
  const assessmentDetail = useSelector(assessmentResponseSelector);
  const abn = lodash.get(assessmentDetail, "data.applicant.abn", "");
  const [assessments, setAssessments] = useState<AssessmentDetail[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAssessments = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await AssessmentService.getLinkedAccountsList(abn, {
        limit: 14,
        type: AssessmentTypes.APPLICATION,
      });

      setAssessments(data.filter(({ id }) => assessmentDetail.id !== id));
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, [abn, assessmentDetail]);

  useEffect(() => {
    if (abn) getAssessments();
  }, [getAssessments, abn]);

  if (isLoading || !assessmentDetail.id) {
    return <Spin />;
  }

  const header = assessments.length ? (
    <>
      <ExclamationCircleOutlined className="icon" />
      Linked accounts found
    </>
  ) : (
    "No linked accounts found"
  );

  return (
    <div className="linked-accounts">
      <div className="header">{header}</div>
      <div className="linked-accounts-wrapper">
        <table className="linked-accounts-table">
          <thead>
            <tr>
              <th className="id-column">Loan ID</th>
              <th className="status-column">Status</th>
              <th className="date-column">Status Date</th>
              <th className="amount-column">Amount Funded</th>
            </tr>
          </thead>
          <tbody>
            {[assessmentDetail, ...assessments].map((assessment) => {
              const { id, externalId, status, data } = assessment;
              const statusDate = new Date(
                (data.settledAt || data.submittedAt) as Date
              );
              let finPowerLink = process.env.REACT_APP_FINPOWER_LINK ?? "";
              finPowerLink = replaceAll(
                finPowerLink,
                "[APPLICATION_NUMBER]",
                data.applicationNumber as string
              );

              const { quote } = (data as unknown) as ApplicationResponse;

              const amountFunded =
                quote.amountFinanced + quote.brokerageNominalWithGst;

              const amountColumn = (
                <NumberFormat
                  thousandSeparator
                  prefix={"$"}
                  value={amountFunded}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                />
              );

              return (
                <tr
                  key={id}
                  className={cs({ current: id === assessmentDetail.id })}
                >
                  <td className="id-column">
                    <Link to={`/application/applications/${externalId}/quotes`}>
                      {lodash.get(data, "applicationNumber", "") as string}
                    </Link>
                  </td>
                  <td className={cs("status-column", status)}>
                    {applicationStatuses.APPLICATION_STATUS_LABELS[status]}
                  </td>
                  <td className="date-column">
                    {dateFormat(statusDate, LONG_DATE_FORMAT)}
                  </td>
                  <td className="amount-column">
                    {status ===
                    applicationStatuses.APPLICATION_STATUSES.SETTLED ? (
                      <a href={finPowerLink} target="_blank" rel="noreferrer">
                        {amountColumn}
                      </a>
                    ) : (
                      amountColumn
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LinkedAccounts;
