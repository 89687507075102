import React, { useState } from "react";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { Button, Popconfirm, message } from "antd";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import LMSService from "../../services/LMSService";
import { FinpowerXmlOption } from "../../types";

export type SubmitApplicationBtnProps = {
  assessmentId: string;
  onSubmitSucess?: () => void;
};

const notifKey = "push-to-lms-notif";

const SubmitApplicationBtn: React.FC<SubmitApplicationBtnProps> = (
  props: SubmitApplicationBtnProps
) => {
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = async (xmlOption = FinpowerXmlOption.ACCOUNT) => {
    setLoading(true);
    message.destroy(notifKey);

    try {
      await LMSService.submitToLms({
        assessmentId: props.assessmentId,
        xmlOption,
      });
      props.onSubmitSucess?.();
      showSuccessPopUp("Application successfully sent to LMS");
    } catch (e) {
      const content = processErrorMessage(e);
      showErrorPopUp(content, notifKey);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Popconfirm
        title="Submit this application to LMS?"
        okText="Submit"
        cancelText="Cancel"
        onConfirm={() => onConfirmHandler()}
        arrowPointAtCenter
      >
        <Button
          className="qf-btn-green mr-2"
          loading={loading}
          data-testid="push-to-lms"
        >
          Push application to LMS
        </Button>
      </Popconfirm>
      <Popconfirm
        title="Submit this NC application to LMS?"
        okText="Submit"
        cancelText="Cancel"
        onConfirm={() =>
          onConfirmHandler(FinpowerXmlOption.NORTWEST_COLLEGE_ACCOUNT)
        }
        arrowPointAtCenter
      >
        <Button
          className="qf-btn-green"
          loading={loading}
          data-testid="push-nortwwest-app-to-lms"
        >
          Push Nortwest College application to LMS
        </Button>
      </Popconfirm>
    </>
  );
};

export default SubmitApplicationBtn;
