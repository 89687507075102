import {
  AuditLogResponse,
  AuditService,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { HTTP_STATUS_CODES } from "@quest-finance/quest-fe-shared/dist/common/constants/httpStatusCodes";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import {
  getLoginHistoriesFailed,
  getLoginHistoriesSuccess,
  assignRolesFailed,
  assignRolesSuccess,
  getUserDetails,
  getUserAuditLogsFailed,
  getUserAuditLogsSuccess,
  getUserDetailsFailed,
  getUserDetailsSuccess,
  saveUserFailed,
  saveUserSuccess,
} from "../actions/creators/userAdmin";
import {
  GetLoginHistories,
  AssignRoles,
  ASSIGN_ROLES,
  GetUserAuditLogs,
  GetUserDetails,
  GET_LOGIN_HISTORIES,
  GET_USER_AUDIT_LOGS,
  GET_USER_DETAILS,
  SaveUser,
  SAVE_USER,
} from "../actions/types/userAdmin";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import UserService from "../services/UserService";
import { LoginHistoryResponse } from "../types/LoginHistory";
import { UserResponse, userResponseDefaultValue } from "../types/UserResponse";

const {
  actionCreator: { setNotification },
} = notification;

function* getUserDetailsWorker({ userId }: GetUserDetails) {
  try {
    if (userId) {
      const result: SingleResponse<UserResponse> = yield call(
        UserService.getUserDetails,
        userId
      );
      yield put(getUserDetailsSuccess(result.data));
    } else {
      yield put(getUserDetailsSuccess(userResponseDefaultValue));
    }
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let notifId = NOTIFICATION_IDS.IAM_USER_DETAILS_ERROR + userId;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        notifId = NOTIFICATION_IDS.IAM_USER_NOT_FOUND + userId;
      }
    }

    yield put(
      setNotification({
        id: notifId,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getUserDetailsFailed());
  }
}

function* saveUserWorker({ user, userId }: SaveUser) {
  try {
    let result: SingleResponse<UserResponse>;
    if (userId) {
      result = yield call(UserService.updateUser, userId, user);
    } else {
      result = yield call(UserService.createUser, user);
    }

    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_USER_SAVE,
        className: "qst-notif-success",
        body: userId ? "User saved" : "New user created",
      })
    );
    yield put(saveUserSuccess(result.data));
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let id = NOTIFICATION_IDS.IAM_USER_SAVE;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        id = NOTIFICATION_IDS.IAM_USER_NOT_FOUND;
      }
    }

    yield put(
      setNotification({
        id,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(saveUserFailed());
  }
}

function* assignRolesWorker({ userId, roleIds }: AssignRoles) {
  try {
    yield call(UserService.assignRoles, userId, { roleIds });
    yield put(assignRolesSuccess());
    yield put(getUserDetails(userId));
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_USER_ASSIGN_ROLES,
        className: "qst-notif-success",
        body: "Roles successfully assigned to the user",
      })
    );
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let id = NOTIFICATION_IDS.IAM_USER_ASSIGN_ROLES;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        id = NOTIFICATION_IDS.IAM_USER_NOT_FOUND;
      }
    }

    yield put(
      setNotification({
        id,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(assignRolesFailed());
  }
}

function* getUserAuditLogsWorker({ payload }: GetUserAuditLogs) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(getUserAuditLogsSuccess(result));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: "user-audit-error",
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getUserAuditLogsFailed());
  }
}

function* getLoginHistoriesWorker({ payload }: GetLoginHistories) {
  try {
    const result: ListResponse<LoginHistoryResponse> = yield call(
      UserService.getLoginHistories,
      payload
    );
    yield put(getLoginHistoriesSuccess(result));
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let id = NOTIFICATION_IDS.IAM_USER_DETAILS_ERROR;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        id = NOTIFICATION_IDS.IAM_USER_NOT_FOUND;
      }
    }

    yield put(
      setNotification({
        id,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getLoginHistoriesFailed());
  }
}

function* watchUserAdmin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(GET_USER_DETAILS, getUserDetailsWorker);
  yield takeLatest(SAVE_USER, saveUserWorker);
  yield takeLatest(ASSIGN_ROLES, assignRolesWorker);
  yield takeLatest(GET_USER_AUDIT_LOGS, getUserAuditLogsWorker);
  yield takeLatest(GET_LOGIN_HISTORIES, getLoginHistoriesWorker);
}

export default watchUserAdmin;
