import "./FunderSection.scss";
import React, { useCallback, useEffect, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Form, Select } from "antd";
import cs from "classnames";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import {
  Funder,
  FunderRequest,
  FunderResponse,
  FunderService,
} from "../../../../funder";
import { DEFAULT_FUNDER_DETAILS } from "../../../../funder/constants/funderDetails";
import { STATUS } from "../../../../funder/types/Funder";
import { funderDetailsSchema } from "../../../../funder/validations/funder";

type FunderSectionProps = {
  submittedToLms: boolean | undefined;
};
const FunderSection: React.FunctionComponent<FunderSectionProps> = (
  props: FunderSectionProps
) => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const { submittedToLms } = props;
  const [loading, setLoading] = useState(false);
  const [funderAssessment, setFunderAssessment] = useState<FunderResponse>();
  const [funderList, setFunderList] = useState<Funder[]>([]);
  const [funderListLoading, setFunderListLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(funderDetailsSchema),
    defaultValues: DEFAULT_FUNDER_DETAILS,
  });

  const getFunderDetails = useCallback(async () => {
    if (!assessmentId) return;
    try {
      setLoading(true);
      const { data } = await FunderService.get(assessmentId);
      if (data) {
        reset({
          funderId: data.funderId,
          utilisationNumber: data.utilisationNumber,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          bsb: data.bsb,
        });
        setFunderAssessment(data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, [assessmentId, reset]);

  const getFunderList = useCallback(async () => {
    setFunderListLoading(true);
    try {
      const { data } = await FunderService.getFunderList();
      setFunderList(data);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setFunderListLoading(false);
    }
  }, []);

  useEffect(() => {
    getFunderList();
  }, [getFunderList]);

  useEffect(() => {
    getFunderDetails();
  }, [getFunderDetails]);

  const handleSaveFunder = async (data: FunderRequest) => {
    try {
      setLoading(true);
      await FunderService.save(assessmentId, data);
      getFunderDetails();
      showSuccessPopUp("Funder details successfully saved");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  };

  const renderFunderOptions = (value?: number) => {
    return funderList.map(
      (funder) =>
        (funder.status === STATUS.ENABLED || funder.id === value) && (
          <Select.Option key={funder.id} value={funder.id}>
            {funder.name}
          </Select.Option>
        )
    );
  };

  return (
    <div className="funder-section">
      <Form>
        <label className="f-bold f-quest-navy">Funder Name</label>
        <div className="form-row-item">
          <Controller
            name="funderId"
            control={control}
            render={({ value, onChange }) => (
              <Select
                onChange={onChange}
                value={value}
                disabled={loading || funderListLoading}
                data-testid="funder-name"
                virtual={false}
              >
                <Select.Option value={0}>Select Funder</Select.Option>
                {renderFunderOptions(funderAssessment?.funderId)}
              </Select>
            )}
          />
          <div>
            <input
              name="utilisationNumber"
              ref={register}
              className="ant-input"
              disabled={loading || funderListLoading}
              placeholder="Utilisation number"
              data-testid="utilisation-number"
            />
          </div>
        </div>
        <label className="f-bold f-quest-navy">Bank Account Details</label>
        {submittedToLms && (
          <i className="d-block" data-testid="bank-act-detail-infotext">
            If bank account needs to be updated during the life of the loan,
            please update this in Finpower directly.
          </i>
        )}
        <div className="form-row-item">
          <div>
            <input
              name="bsb"
              ref={register}
              disabled={submittedToLms}
              className={cs("ant-input", {
                invalid: errors?.bsb,
              })}
              placeholder="BSB"
              data-testid="bsb"
            />
            {errors?.bsb?.message && (
              <div className="validation" data-testid="bsb-error">
                {errors.bsb.message}
              </div>
            )}
          </div>
          <div>
            <input
              name="accountNumber"
              ref={register}
              className={cs("ant-input", {
                invalid: errors?.accountNumber,
              })}
              disabled={submittedToLms}
              placeholder="Account number"
              data-testid="account-number"
            />
            {errors?.accountNumber?.message && (
              <div className="validation" data-testid="act-num-error">
                {errors.accountNumber.message}
              </div>
            )}
          </div>
          <div>
            <input
              name="accountName"
              ref={register}
              className="ant-input"
              disabled={submittedToLms}
              placeholder="Account name"
              data-testid="account-name"
            />
          </div>
        </div>
        <div>
          <Button
            onClick={handleSubmit(handleSaveFunder)}
            loading={funderListLoading || loading}
            data-testid="save-funder"
            className="qf-btn-green"
          >
            Save
          </Button>
        </div>
      </Form>
      {funderAssessment && typeof funderAssessment.submittedBy !== "number" && (
        <div data-testid="funder-submitted-info" className="submit-info">
          Submitted
          {funderAssessment.funder && (
            <span data-testid="funder-submitted-to">{` to ${funderAssessment.funder.name}`}</span>
          )}
          {funderAssessment.submittedBy && (
            <span data-testid="funder-submitted-by">{` by ${funderAssessment.submittedBy.firstName} ${funderAssessment.submittedBy.lastName}`}</span>
          )}{" "}
          -{" "}
          <span data-testid="funder-submitted-at">{`${dateFormat(
            new Date(funderAssessment.submittedAt),
            "MMM d h:mm a"
          )}`}</span>
        </div>
      )}
    </div>
  );
};

export default FunderSection;
