import {
  AuditLogResponse,
  AuditService,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { HTTP_STATUS_CODES } from "@quest-finance/quest-fe-shared/dist/common/constants/httpStatusCodes";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import {
  getClientAuditLogsFailed,
  getClientAuditLogsSuccess,
  getClientDetailsFailed,
  getClientDetailsSuccess,
  getPricingFailed,
  getPricingSuccess,
  saveClientFailed,
  saveClientSuccess,
} from "../actions/creators/clientAdmin";
import {
  GetClientAuditLogs,
  GetClientDetails,
  GET_CLIENT_AUDIT_LOGS,
  GET_CLIENT_DETAILS,
  GET_PRICING,
  SaveClient,
  SAVE_CLIENT,
} from "../actions/types/clientAdmin";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import ClientService from "../services/ClientService";
import {
  ClientResponse,
  clientResponseDefaultValue,
} from "../types/ClientResponse";

const {
  actionCreator: { clearNotification, setNotification },
} = notification;

function* getClientDetailsWorker({ clientId }: GetClientDetails) {
  try {
    yield put(clearNotification());
    if (clientId) {
      const result: SingleResponse<ClientResponse> = yield call(
        ClientService.getClientDetails,
        clientId
      );
      yield put(getClientDetailsSuccess(result.data));
    } else {
      yield put(getClientDetailsSuccess(clientResponseDefaultValue));
    }
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let notifId = NOTIFICATION_IDS.IAM_CLIENT_DETAILS_ERROR + clientId;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        notifId = NOTIFICATION_IDS.IAM_CLIENT_NOT_FOUND + clientId;
      }
    }

    yield put(
      setNotification({
        id: notifId,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getClientDetailsFailed());
  }
}

function* saveClientWorker({ clientForm, clientId }: SaveClient) {
  try {
    yield put(clearNotification());
    if (clientId) {
      yield call(ClientService.updateClient, clientId, clientForm);
    } else {
      yield call(ClientService.createClient, clientForm);
    }
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_CLIENT_SAVE,
        className: "qst-notif-success",
        body: clientId
          ? "Client successfully updated"
          : "Client successfully created",
      })
    );
    yield put(saveClientSuccess());
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let id = NOTIFICATION_IDS.IAM_CLIENT_SAVE;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        id = NOTIFICATION_IDS.IAM_CLIENT_NOT_FOUND;
      }
    }

    yield put(
      setNotification({
        id,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(saveClientFailed());
  }
}

function* getPricingWorker() {
  try {
    yield put(clearNotification());
    const result: SingleResponse<Record<string, string>> = yield call(
      ClientService.getPricing
    );
    yield put(getPricingSuccess(result.data));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: "pricing-error",
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getPricingFailed());
  }
}

function* getClientAuditLogsWorker({ payload }: GetClientAuditLogs) {
  try {
    yield put(clearNotification());
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(getClientAuditLogsSuccess(result));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: "client-audit-error",
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getClientAuditLogsFailed());
  }
}

function* watchClientAdmin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(GET_CLIENT_DETAILS, getClientDetailsWorker);
  yield takeLatest(SAVE_CLIENT, saveClientWorker);
  yield takeLatest(GET_PRICING, getPricingWorker);
  yield takeLatest(GET_CLIENT_AUDIT_LOGS, getClientAuditLogsWorker);
}

export default watchClientAdmin;
