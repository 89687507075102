import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";

import { ClientForm } from "../../types/ClientForm";
import { ClientResponse } from "../../types/ClientResponse";

export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export interface GetClientDetails extends Action {
  type: typeof GET_CLIENT_DETAILS;
  clientId?: number;
}

export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export interface GetClientDetailsSuccess extends Action {
  type: typeof GET_CLIENT_DETAILS_SUCCESS;
  client: ClientResponse;
}

export const GET_CLIENT_DETAILS_FAILED = "GET_CLIENT_DETAILS_FAILED";
export interface GetClientDetailsFailed extends Action {
  type: typeof GET_CLIENT_DETAILS_FAILED;
}

type GetClientActions =
  | GetClientDetails
  | GetClientDetailsSuccess
  | GetClientDetailsFailed;

export const SAVE_CLIENT = "SAVE_CLIENT";
export interface SaveClient extends Action {
  type: typeof SAVE_CLIENT;
  clientForm: ClientForm;
  clientId?: number;
}

export const SAVE_CLIENT_SUCCESS = "SAVE_CLIENT_SUCCESS";
export interface SaveClientSuccess extends Action {
  type: typeof SAVE_CLIENT_SUCCESS;
}

export const SAVE_CLIENT_FAILED = "SAVE_CLIENT_FAILED";
export interface SaveClientFailed extends Action {
  type: typeof SAVE_CLIENT_FAILED;
}

type SaveClientActions = SaveClient | SaveClientSuccess | SaveClientFailed;

export const UPDATE_CLIENT_FORM = "UPDATE_CLIENT_FORM";
export interface UpdateClientForm extends Action {
  type: typeof UPDATE_CLIENT_FORM;
  clientForm: Partial<ClientForm>;
}

export const RESET_FORM_STATE = "RESET_FORM_STATE";
export interface ResetFormState extends Action {
  type: typeof RESET_FORM_STATE;
}

export const GET_PRICING = "GET_PRICING";
export interface GetPricing extends Action {
  type: typeof GET_PRICING;
}

export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export interface GetPricingSuccess extends Action {
  type: typeof GET_PRICING_SUCCESS;
  pricing: Record<string, string>;
}

export const GET_PRICING_FAILED = "GET_PRICING_FAILED";
export interface GetPricingFailed extends Action {
  type: typeof GET_PRICING_FAILED;
}

type PricingActions = GetPricing | GetPricingSuccess | GetPricingFailed;

export const GET_CLIENT_AUDIT_LOGS = "GET_CLIENT_AUDIT_LOGS";
export interface GetClientAuditLogs extends Action {
  type: typeof GET_CLIENT_AUDIT_LOGS;
  payload: AuditLogListRequest;
}

export const GET_CLIENT_AUDIT_LOGS_SUCCESS = "GET_CLIENT_AUDIT_LOGS_SUCCESS";
export interface GetClientAuditLogsSuccess extends Action {
  type: typeof GET_CLIENT_AUDIT_LOGS_SUCCESS;
  auditLogs: ListResponse<AuditLogResponse>;
}

export const GET_CLIENT_AUDIT_LOGS_FAILED = "GET_CLIENT_AUDIT_LOGS_FAILED";
export interface GetClientAuditLogsFailed extends Action {
  type: typeof GET_CLIENT_AUDIT_LOGS_FAILED;
}

type ClientAuditLogsActions =
  | GetClientAuditLogs
  | GetClientAuditLogsSuccess
  | GetClientAuditLogsFailed;

export type ClientAdminActions =
  | GetClientActions
  | SaveClientActions
  | UpdateClientForm
  | ResetFormState
  | PricingActions
  | ClientAuditLogsActions;
